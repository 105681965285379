exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelles-js": () => import("./../../../src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-beratungsangebot-js": () => import("./../../../src/pages/beratungsangebot.js" /* webpackChunkName: "component---src-pages-beratungsangebot-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interessante-themen-js": () => import("./../../../src/pages/interessante-themen.js" /* webpackChunkName: "component---src-pages-interessante-themen-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-ueberuns-js": () => import("./../../../src/pages/ueberuns.js" /* webpackChunkName: "component---src-pages-ueberuns-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

